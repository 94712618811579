import Image from 'next/image';

const FOOTER_PROPS = {
  tagline: 'The modern customer service platform.',
  badgeIcons: [
    <Image key="badge-1" width={83} height={107} src="/images/g2-front-best-usability-fall-2024.svg" className="block" alt="g2-badge-best-usability" />,
    <Image key="badge-2" width={83} height={107} src="/images/g2-front-leader-fall-2024.svg" className="block" alt="g2-badge-leader" />,
    <Image key="badge-3" width={83} height={107} src="/images/g2-front-momentum-leader-fall-2024.svg" className="block" alt="g2-momentum-leader" />,
  ],

  socialLinks: [
    {
      title: 'Linked In',
      href: 'https://www.linkedin.com/company/fronthq',
      icon: <Image width={27} height={27} src="/images/icon-social-linkedin.svg" className="block" alt="Linked In" />,
    },
    {
      title: 'Facebook',
      href: 'https://www.facebook.com/FrontHQ',
      icon: <Image width={27} height={27} src="/images/icon-social-facebook.svg" className="block" alt="Facebook" />,
    },
    {
      title: 'Instagram',
      href: 'https://www.instagram.com/FrontHQ/',
      icon: <Image width={27} height={27} src="/images/icon-social-instagram.svg" className="block" alt="Instagram" />,
    },
  ],

  linkColumns: [
    {
      sections: [
        {
          title: 'Product',
          links: [
            { title: 'Front Overview', href: '/product' },
            { title: 'Help Desk', href: '/teams/customer-service-support' },
            { title: 'Shared Inbox', href: '/product/omnichannel-inbox' },
            { title: 'Ticketing', href: '/product/ticketing' },
            { title: 'Knowledge Base', href: '/product/knowledge-base' },
            { title: 'Live Chat', href: '/product/live-chat' },
            { title: 'Team Collaboration', href: '/product/collaboration' },
            { title: 'AI & Automation', href: '/product/ai' },
            { title: 'Insights', href: '/product/analytics' },
            { title: 'Support Report', href: '/product/support-report' },
            { title: 'Integrations', href: '/product/integrations-api' },
          ],
        },
        /*
         *{
         *  title: 'Features',
         *  links: [
         *    { title: 'Team email collaboration', href: '/product/collaboration' },
         *    { title: 'AI & Automation', href: '/product/ai' },
         *    { title: 'Omnichannel', href: '/product/analytics' },
         *    { title: 'Support Report', href: '/product/support-report' },
         *  ],
         *},
         */
      ],
      /*
       *featuredLinks: [
       *  { title: 'Download App', href: '/download' },
       *  { title: 'Pricing', href: '/pricing' },
       *],
       */
    },
    {
      sections: [
        {
          title: 'Use Cases',
          links: [
            { title: 'Customer Service', href: '/customer-service' },
            { title: 'Email Management', href: '/email-management' },
            { title: 'Client Communication', href: '/client-communication' },
          ],
        },
        {
          title: 'Explore',
          links: [
            { title: 'Pricing', href: '/pricing' },
            { title: 'Product Updates', href: 'https://community.front.com/product-updates' },
            { title: 'Download App', href: '/download' },
          ],
        },
      ],
      /*
       *featuredLinks: [
       *  { title: 'Product Updates', href: 'https://community.front.com/product-updates' },
       *  { title: 'API', href: '/product/integrations-api' },
       *],
       */
    },
    {
      sections: [
        {
          title: 'Compare',
          links: [
            { title: 'Front vs. Email', href: '/compare/front-vs-email' },
            { title: 'Front vs. Zendesk', href: '/blog/front-versus-zendesk' },
            { title: 'Front vs. Freshdesk', href: '/blog/front-versus-freshdesk' },
          ],
        },        
        {
          title: 'Resources',
          links: [
            { title: 'Blog', href: '/blog' },
            { title: 'Customer Stories', href: '/customer-stories' },
            { title: 'Help', href: 'https://help.front.com/' },
            { title: 'Academy', href: 'https://academy.front.com/app' },
            { title: 'Developers', href: 'https://dev.frontapp.com/docs/welcome' },
            { title: 'API', href: '/product/integrations-api' },
          ],
        },
      ],
    },
    {
      sections: [
        {
          title: 'Company',
          links: [
            { title: 'About', href: '/about' },
            { title: 'Careers', href: '/jobs' },
            { title: 'Press', href: '/press' },
            { title: 'Real-Time Metrics', href: '/support-report' },
            { title: 'Become a Partner', href: '/partners/integration' },
            { title: 'Customer Referral', href: '/customer-referral' },
            { title: 'Contact Support', href: 'https://help.front.com/en/contact-us' },
            { title: 'Legal', href: '/legal' },
          ],
        },
      ],
    },
  ],

  smallLinksTitle: 'Additional Resources',
  smallLinkColumns: [
    {
      title: 'Customer Support and Service',
      links: [
        {
          title: 'Customer Support Metrics Library',
          href: '/guides/top-customer-service-metrics',
        },
        { title: 'Guide to 100% CSAT', href: '/blog/improve-csat' },
        { title: 'Building a Customer Service QA Scorecard', href: '/resources/how-to-build-a-customer-service-qa-scorecard' },
      ],
    },
    {
      title: 'AI and Automation',
      links: [
        { title: 'Boost Agent Support Productivity', href: '/blog/customer-service-automation-ai' },
        { title: 'Front\'s New AI Capabilities', href: '/blog/customer-service-agent-assistance-ai' },
        {
          title: 'AI vs. Human Support Strategy',
          href: '/resources/ai-vs-human-why-you-dont-have-to-choose',
        },
      ],
    },
    {
      title: 'Knowledge Base',
      links: [
        { title: '5 Best Practices for Self-Service Support', href: '/resources/how-to-scale-delightful-customer-experiences-with-self-service-support' },
        { title: 'Create AI-Friendly Help Center Articles', href: '/resources/creating-ai-friendly-help-center-articles' },
        { title: 'Top Tips for Knowledge Base Articles', href: '/blog/how-to-write-knowledge-base-articles' },
      ],
    },
    {
      title: 'More',
      links: [
        { title: 'Support Message Templates', href: '/resources/5-tried-and-true-message-templates-to-improve-your-support-teams-csat' },
        { title: 'Shared Inbox Management Tips', href: '/guides/shared-inbox-management' },
        {
          title: 'Front for Startups',
          href: '/blog/introducing-front-for-startups',
        },
      ],
    },
  ],
  legalLinks: [
    { title: 'SaaS Service Agreement', href: '/legal/saas-services-agreement' },
    { title: 'Privacy Notice', href: '/legal/privacy-notice' },
    { title: 'Cookie Policy', href: '/legal/cookie-policy' },
    {
      title: 'Google User Data Privacy Notice',
      href: '/legal/front-products-and-services-privacy-notice#google_user_data_privacy_notice',
    },
    { title: 'Status', href: 'https://www.frontstatus.com/' },
  ],
  copyright: '2025 FrontApp, Inc. All Rights Reserved.',
};

export default FOOTER_PROPS;
